.notification-list {
  @apply w-full flex flex-col items-center space-y-2 sm:items-end;
}

.notification-bar {
  @apply max-w-sm w-full flex flex-row items-center bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden;

  &::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 20px;
  }
}

.notification-bar-success {
  @apply text-success-500;

  &::before {
    @apply ml-4;
    content: '\f058';
  }
}

.notification-bar-error {
  @apply text-danger-500 ml-4;

  &::before {
    @apply ml-4;
    content: '\f057';
  }
}

.notification-bar-info {
  @apply text-primary-500 ml-4;

  &::before {
    @apply ml-4;
    content: '\f05a';
  }
}

.notification-bar-wrapper {
  @apply p-4 flex items-start;
}

.notification-bar-title {
  @apply mb-1 text-sm font-medium text-gray-900;
}

.notification-bar-message {
  @apply text-sm text-gray-700;
}

.compose-form {
  &__sensitive-button {
    padding: 10px;
    padding-top: 0;
    font-size: 14px;
    font-weight: 500;
    &.active { color: var(--highlight-text-color); }
    input[type=checkbox] { display: none; }

    .checkbox {
      display: inline-block;
      position: relative;
      border: 1px solid var(--brand-color);
      box-sizing: border-box;
      width: 18px;
      height: 18px;
      flex: 0 0 auto;
      margin-right: 10px;
      top: -1px;
      border-radius: 4px;
      vertical-align: middle;

      &.active {
        border-color: var(--highlight-text-color);
        background: var(--highlight-text-color);
      }
    }
  }

  &__warning {
    color: var(--primary-text-color);
    margin-bottom: 10px;
    background: var(--brand-color--faint);
    box-shadow: 0 2px 6px rgba($base-shadow-color, 0.3);
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;

    strong {
      color: var(--primary-text-color);
      font-weight: 500;
      @each $lang in $cjk-langs {
        &:lang(#{$lang}) {
          font-weight: 700;
        }
      }
    }

    a {
      color: var(--brand-color--hicontrast);
      font-weight: 500;
      text-decoration: underline;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }

  &__autosuggest-wrapper {
    position: relative;
  }

  .spoiler-input {
    height: 0;
    transform-origin: bottom;
    opacity: 0;

    &.spoiler-input--visible {
      height: 36px;
      margin-bottom: 11px;
      opacity: 1;
    }
  }

  .spoiler-input__input { border-radius: 4px; }

  &.condensed {
    .autosuggest-textarea__textarea {
      min-height: 46px;
      border-radius: 5px;
    }

    .compose-form__buttons-wrapper {
      height: 0;
      padding: 0;
      overflow: hidden;
    }
  }

  &__modifiers {
    color: var(--primary-text-color);
    font-family: inherit;
    font-size: 14px;
    background: var(--background-color);
    .compose-form__upload-wrapper { overflow: hidden; }

    .compose-form__uploads-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &.contains-media {
        padding: 5px;
        border-top: 1px solid var(--foreground-color);
      }
    }

    .compose-form__upload {
      flex: 1 1 0;
      min-width: 40%;
      margin: 5px;
      position: relative;
      border-radius: 4px;
      overflow: hidden;

      &__actions {
        background: linear-gradient(180deg, rgba($base-shadow-color, 0.8) 0, rgba($base-shadow-color, 0.35) 80%, transparent);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        opacity: 0;
        transition: opacity 0.1s ease;

        .icon-button {
          flex: 0 1 auto;
          color: #fff;
          font-size: 14px;
          font-weight: 500;
          padding: 10px;
          font-family: inherit;

          &:hover,
          &:focus,
          &:active {
            color: var(--accent-color);
          }
        }
        &.active { opacity: 1; }
      }

      &-description {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        right: 0;
        box-sizing: border-box;
        background: linear-gradient(0deg, rgba($base-shadow-color, 0.8) 0, rgba($base-shadow-color, 0.35) 80%, transparent);
        padding: 10px;
        opacity: 0;
        transition: opacity 0.1s ease;

        textarea {
          background: transparent;
          box-sizing: border-box;
          color: #fff;
          border: 1px solid #fff;
          outline: none;
          padding: 10px;
          margin: 0;
          width: 100%;
          font-family: inherit;
          font-size: 14px;
          font-weight: 500;

          &:focus { color: #fff; }

          &::placeholder {
            color: rgba(255, 255, 255, 0.6);
          }
        }
        &.active { opacity: 1; }
      }

      &-preview {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        > i.fa {
          width: 100%;
          color: hsla(var(--primary-text-color_hsl), 0.4);
          font-size: 64px;
          text-align: center;
          line-height: 160px;
        }
      }
    }

    .compose-form__upload-thumbnail {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 160px;
      width: 100%;
      overflow: hidden;
      position: relative;

      &.video {
        background-image: url('../images/video-placeholder.png');
        background-size: cover;
      }

      &.audio {
        background-image: url('../images/audio-placeholder.png');
        background-size: cover;
      }
    }
  } // end .compose-form .compose-form__modifiers

  &__buttons-wrapper {
    padding: 10px;
    background: var(--background-color);
    display: flex;
    justify-content: space-between;
    flex: 0 0 auto;
    border-radius: 0 0 5px 5px;

    .compose-form__buttons {
      display: flex;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .compose-form__sensitive-button {
        display: none;

        &.compose-form__sensitive-button--visible {
          display: block;
        }
      }

      .svg-icon {
        width: 24px;
        height: 24px;

        svg {
          stroke-width: 1.5px;
        }
      }

      .privacy-dropdown__value {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .icon-button {
      box-sizing: content-box;
      padding: 0 3px;
      opacity: 0.5;
      transition: 0.2s;

      &.active,
      &:hover {
        opacity: 1;
      }
    }

    .character-counter {
      display: block;
      cursor: default;
      font-family: var(--font-sans-serif), sans-serif;
      font-size: 14px;
      font-weight: 600;
      color: var(--primary-text-color--faint);
      &.character-counter--over { color: $warning-red; }
    }

    .character-counter,
    .visual-character-counter {
      margin-right: 10px;
    }
  }

  &__publish {
    display: flex;
    justify-content: flex-end;
    min-width: 0;
    flex: 0 0 auto;

    .compose-form__publish-button-wrapper {
      overflow: hidden;
    }
  }

  &__counter {
    display: flex;
    align-items: center;
    align-self: center;
    margin-left: auto;
  }
} // end .compose-form

// Icon tweaks
.compose-form__spoiler-button-icon {
  transform: translateY(2px);
}

.compose-form__markdown-button-icon {
  transform: translateY(1px);
}

.upload-progress {
  padding: 10px;
  color: var(--highlight-text-color);
  overflow: hidden;
  display: flex;

  .fa {
    font-size: 34px;
    margin-right: 10px;
  }

  span {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    display: block;
  }
}

.upload-progess__message {
  flex: 1 1 auto;
}

.upload-progress__backdrop {
  width: 100%;
  height: 6px;
  border-radius: 6px;
  background: var(--brand-color--med);
  position: relative;
  margin-top: 5px;
}

.upload-progress__tracker {
  position: absolute;
  left: 0;
  top: 0;
  height: 6px;
  background: var(--brand-color);
  border-radius: 6px;
}

.privacy-dropdown__dropdown {
  font-size: 14px;
  position: absolute;
  background: var(--foreground-color);
  box-shadow: 2px 4px 15px rgba($base-shadow-color, 0.4);
  border-radius: 4px;
  margin-left: 40px;
  overflow: hidden;
  z-index: 10000;

  &.top {
    transform-origin: 50% 100%;
  }

  &.bottom {
    transform-origin: 50% 0;
  }
}

.privacy-dropdown__option {
  color: var(--primary-text-color);
  padding: 10px;
  cursor: pointer;
  display: flex;

  &:hover,
  &.active {
    background: var(--brand-color--med);
    color: var(--primary-text-color);
    outline: 0;

    .privacy-dropdown__option__content {
      color: var(--primary-text-color);

      strong {
        color: var(--primary-text-color);
      }
    }
  }

  &.active:hover {
    background: hsla(var(--brand-color_hsl), 0.5);
  }
}

.privacy-dropdown__option__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.privacy-dropdown__option__content {
  flex: 1 1 auto;
  color: var(--highlight-text-color);

  strong {
    font-weight: 500;
    display: block;
    color: var(--primary-text-color);

    @each $lang in $cjk-langs {
      &:lang(#{$lang}) {
        font-weight: 700;
      }
    }
  }
}

.privacy-dropdown.active {
  .privacy-dropdown__value {
    background: var(--foreground-color);
    border-radius: 4px 4px 0 0;
    box-shadow: 0 -4px 4px rgba($base-shadow-color, 0.1);

    .icon-button {
      transition: none;
    }

    &.active {
      background: var(--brand-color);

      .icon-button {
        color: var(--primary-text-color);
      }
    }
  }

  &.top .privacy-dropdown__value {
    border-radius: 0 0 4px 4px;
  }

  .privacy-dropdown__dropdown {
    display: block;
    box-shadow: 2px 4px 6px rgba($base-shadow-color, 0.1);
  }
}

.focal-point {
  position: relative;
  cursor: pointer;
  overflow: hidden;

  &.dragging {
    cursor: move;
  }

  img {
    max-width: 80vw;
    max-height: 80vh;
    width: auto;
    height: auto;
    margin: auto;
  }

  &__reticle {
    position: absolute;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);
    background: url('../images/reticle.png') no-repeat 0 0;
    border-radius: 50%;
    box-shadow: 0 0 0 9999em rgba($base-shadow-color, 0.35);
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.video-error-cover {
  align-items: center;
  background: var(--background-color);
  color: var(--primary-text-color);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-top: 8px;
  position: relative;
  text-align: center;
  z-index: 100;
}

.status__video-player {
  background: var(--background-color);
  box-sizing: border-box;
  cursor: default; /* May not be needed */
  margin-top: 8px;
  overflow: hidden;
  position: relative;
}

.status__video-player-video {
  height: 100%;
  object-fit: cover;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1;
}

.status__video-player-expand,
.status__video-player-mute {
  color: var(--primary-text-color);
  opacity: 0.8;
  position: absolute;
  right: 4px;
  text-shadow: 0 1px 1px $base-shadow-color, 1px 0 1px $base-shadow-color;
}

.status__video-player-spoiler {
  display: none;
  color: var(--primary-text-color);
  left: 4px;
  position: absolute;
  text-shadow: 0 1px 1px $base-shadow-color, 1px 0 1px $base-shadow-color;
  top: 4px;
  z-index: 100;

  &.status__video-player-spoiler--visible {
    display: block;
  }
}

.status__video-player-expand {
  bottom: 4px;
  z-index: 100;
}

.status__video-player-mute {
  top: 4px;
  z-index: 5;
}

.detailed,
.fullscreen {
  .video-player__volume__current,
  .video-player__volume::before {
    bottom: 27px;
  }

  .video-player__volume__handle {
    bottom: 23px;
  }
}

.video-player {
  overflow: hidden;
  position: relative;
  background: $base-shadow-color;
  max-width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  direction: ltr;
  color: white;

  &.editable {
    border-radius: 0;
    height: 100% !important;
  }

  &:focus {
    outline: 0;
  }

  video {
    display: block;
    z-index: 1;
  }

  &.fullscreen {
    width: 100% !important;
    height: 100% !important;
    margin: 0;

    video {
      max-width: 100% !important;
      max-height: 100% !important;
      width: 100% !important;
      height: 100% !important;
      outline: 0;
    }
  }

  &--inline {
    video {
      object-fit: contain;
      position: relative;
    }
  }

  &__controls {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    background: linear-gradient(0deg, rgba($base-shadow-color, 0.85) 0, rgba($base-shadow-color, 0.45) 60%, transparent);
    padding: 0 15px;
    opacity: 0;
    transition: opacity 0.1s ease;

    &.active {
      opacity: 1;
    }
  }

  &--inactive {
    min-height: 300px;

    video,
    .video-player__controls {
      visibility: hidden;
    }
  }

  &__spoiler {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    border: 0;
    background: var(--background-color);
    color: var(--primary-text-color--faint);
    transition: none;
    pointer-events: none;

    &.active {
      display: block;
      pointer-events: auto;

      &:hover,
      &:active,
      &:focus {
        color: var(--primary-text-color);
      }
    }

    &__title {
      display: block;
      font-size: 14px;
    }

    &__subtitle {
      display: block;
      font-size: 11px;
      font-weight: 500;
    }
  }

  &__buttons-bar {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    margin: 0 -5px;

    .video-player__download__icon {
      color: inherit;
    }
  }

  &__buttons {
    display: flex;
    flex: 0 1 auto;
    min-width: 30px;
    align-items: center;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .player-button {
      display: inline-block;
      outline: 0;
      flex: 0 0 auto;
      background: transparent;
      padding: 5px 6px;
      font-size: 16px;
      border: 0;
      color: rgba(#fff, 0.75);

      .svg-icon {
        width: 20px;
        height: 20px;
      }

      &:active,
      &:hover,
      &:focus {
        color: #fff;
      }
    }
  }

  &__time {
    display: inline;
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 5px;
  }

  &__time-sep,
  &__time-total,
  &__time-current {
    font-size: 14px;
    font-weight: 500;
  }

  &__time-current {
    color: #fff;
  }

  &__time-sep {
    display: inline-block;
    margin: 0 6px;
  }

  &__time-sep,
  &__time-total {
    color: #fff;
  }

  &__volume {
    flex: 0 0 auto;
    display: inline-flex;
    cursor: pointer;
    height: 24px;
    position: relative;
    overflow: hidden;

    .no-reduce-motion & {
      transition: all 100ms linear;
    }

    &.active {
      overflow: visible;
      width: 50px;
      margin-right: 16px;
    }

    &::before {
      content: "";
      width: 50px;
      background: rgba(#fff, 0.35);
      border-radius: 4px;
      display: block;
      position: absolute;
      height: 4px;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }

    &__current {
      display: block;
      position: absolute;
      height: 4px;
      border-radius: 4px;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      background: var(--accent-color);
    }

    &__handle {
      position: absolute;
      z-index: 3;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      top: 50%;
      left: 0;
      margin-left: -6px;
      transform: translate(0, -50%);
      background: var(--accent-color);
      box-shadow: 1px 2px 6px rgba($base-shadow-color, 0.2);
      opacity: 0;

      .no-reduce-motion & {
        transition: opacity 100ms linear;
      }
    }

    &.active &__handle {
      opacity: 1;
    }
  }

  &__link {
    padding: 2px 10px;

    a {
      text-decoration: none;
      font-size: 14px;
      font-weight: 500;
      color: #fff;

      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &__seek {
    cursor: pointer;
    height: 24px;
    position: relative;

    &::before {
      content: "";
      width: 100%;
      background: rgba(#fff, 0.35);
      border-radius: 4px;
      display: block;
      position: absolute;
      height: 4px;
      top: 14px;
    }

    &__progress,
    &__buffer {
      display: block;
      position: absolute;
      height: 4px;
      border-radius: 4px;
      top: 14px;
      background: var(--accent-color);
    }

    &__buffer {
      background: rgba(#fff, 0.2);
    }

    &__handle {
      position: absolute;
      z-index: 3;
      opacity: 0;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      top: 10px;
      margin-left: -6px;
      background: var(--accent-color);
      box-shadow: 1px 2px 6px rgba($base-shadow-color, 0.2);

      .no-reduce-motion & {
        transition: opacity 0.1s ease;
      }

      &.active {
        opacity: 1;
      }
    }

    &:hover {
      .video-player__seek__handle {
        opacity: 1;
      }
    }
  }

  &.detailed,
  &.fullscreen {
    .video-player__buttons {
      .player-button {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

.media-spoiler-video {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin-top: 8px;
  position: relative;
  border: 0;
  display: block;
}

.media-spoiler-video-play-icon {
  border-radius: 100px;
  color: var(--primary-text-color--faint);
  font-size: 36px;
  left: 50%;
  padding: 5px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

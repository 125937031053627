.sidebar-menu {
  @apply flex inset-0 fixed flex-col w-80 bg-white transition-all ease-linear -translate-x-80 z-1000;

  &__wrapper {
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.3);
    transition: opacity 0.2s linear;
    transition-delay: 0.1s;
    pointer-events: none;
  }

  &__content {
    position: relative;
    overflow-y: scroll;
    overflow: auto;
    height: 100%;
    width: 100%;
  }

  &__section {
    display: flex;
    flex-direction: column;
    padding: 4px 0;
    border-top: 1px solid hsla(var(--primary-text-color_hsl), 0.15);

    &--borderless {
      margin: 0;
      border-top: 0;
    }
  }

  @media (max-width: 400px) {
    width: 90vw;
    transform: translateX(-90vw);
  }
}

.sidebar-menu__root--visible {
  .sidebar-menu {
    transform: translateX(0);
  }

  .sidebar-menu__wrapper {
    opacity: 1;
    pointer-events: all;
  }
}

.sidebar-menu-header {
  display: flex;
  height: 46px;
  padding: 12px 14px;
  border-bottom: 1px solid hsla(var(--primary-text-color_hsl), 0.15);
  box-sizing: border-box;
  align-items: center;

  &__title {
    display: block;
    font-size: 18px;
    font-weight: 600;
    color: var(--primary-text-color);
  }

  &__btn {
    margin-left: auto;
  }
}

.sidebar-menu__close {
  position: absolute;
  top: 16px;
  right: 18px;

  .svg-icon {
    width: 32px;
    height: 32px;

    svg {
      stroke-width: 1px;
    }
  }
}

.sidebar-menu-profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 14px 18px;
  box-sizing: border-box;

  &__avatar {
    display: block;
    width: 56px;
    height: 56px;
  }

  &__name {
    display: flex;
    margin-top: 10px;
    color: var(--primary-text-color);
    text-decoration: none;
    align-items: center;

    .display-name__account {
      display: block;
      margin-top: 2px;
      color: var(--primary-text-color--faint);
    }
  }

  &__caret {
    margin-left: auto;
    padding-left: 10px;

    svg {
      stroke-width: 1px;
    }
  }
}

.sidebar-account {
  text-decoration: none;
}

.sidebar-menu-item {
  display: flex;
  padding: 16px 18px;
  cursor: pointer;
  text-decoration: none;
  color: var(--primary-text-color--faint);
  font-size: 15px;
  font-weight: 500;
  align-items: center;

  &:hover {
    background-color: hsla(var(--brand-color_hsl), 0.1);
    color: var(--primary-text-color);
  }

  > .fa {
    width: 24px;
    font-size: 28px;
    margin-right: 15px;
    text-align: center;
  }

  > .svg-icon {
    width: 28px;
    height: 28px;
    margin-right: 15px;

    svg.icon-tabler,
    svg.feather {
      stroke-width: 1px;
    }
  }

  &:hover {
    &__title {
      color: var(--primary-text-color);
    }
  }
}

.sidebar-menu .wtf-panel {
  box-shadow: none !important;
  margin: 0;
}

.badge {
  @apply inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-primary-600 text-white;

  &--patron {
    @apply bg-primary-600 text-white;
  }

  &--admin {
    @apply bg-black;
  }

  &--moderator {
    @apply bg-cyan-600 text-white;
  }

  &--bot {
    @apply bg-gray-100 text-gray-800;
  }

  &--opaque {
    @apply bg-white bg-opacity-75 text-gray-900;
  }
}

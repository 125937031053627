.wtf-panel {
  @include standard-panel;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: auto;
  box-sizing: border-box;

  @media screen and (max-width: 580px) {
    border-radius: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  .wtf-panel-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 15px 15px 0;

    &__icon {
      margin-right: 10px;
      font-size: 20px;

      &.svg-icon {
        width: 20px;
        height: 20px;
      }
    }

    &__label {
      flex: 1 1;
      color: var(--primary-text-color);
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
    }
  }

  &__content {
    width: 100%;
    padding-top: 8px;
  }

  &__list {
    padding: 0 5px;
  }

  &__subtitle {
    display: block;
    padding: 0 15px;
    color: var(--primary-text-color--faint);
  }

  &__form {
    display: block;
    padding: 15px;

    &.button {
      width: 100%;
    }
  }

  .wtf-panel-list-item {
    display: block;
    padding-bottom: 10px;

    &:not(:first-of-type) {
      margin-top: 12px;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--brand-color--med);
    }

    &__content {
      display: flex;
      flex-direction: row;
      min-height: 46px;
      margin-left: 58px;
    }

    &__account-block {
      display: flex;
      position: relative;
      align-items: baseline;
      padding-right: 10px;

      &__avatar {
        height: 46px;
        width: 46px;
        background-color: #f00;
        left: -58px;
        position: absolute;
      }

      &__name {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        margin-top: 6px;

        &__name {
          color: var(--primary-text-color);
          font-size: 14px;
          font-weight: bold;
          line-height: 16px;
          margin-bottom: 2px;
          max-height: 32px; //2 lines of text
          overflow: hidden;
        }

        &__username {
          color: var(--highlight-text-color);
          font-size: 12px;
          line-height: 14px;
        }
      }
    }

    &__follow-block {
      margin-left: auto;
      padding-top: 6px;

      &__button {
        display: flex;
      }

      &__icon {
        color: var(--primary-text-color);
      }
    }
  }

  &__expand-btn {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 46px;
    position: relative;
    border-top: 1px solid;
    border-color: var(--brand-color--faint);
    transition: max-height 150ms ease;
    overflow: hidden;
    opacity: 1;
    text-align: center;
    line-height: 46px;
    font-size: 14px;
    cursor: pointer;
    color: var(--primary-text-color);
    text-decoration: none;
  }

  &__menu {
    margin-left: auto;

    > div {
      height: 18px;
    }
  }
}

.column .sub-navigation ~ .wtf-panel {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
